const actionTypes = {
  FetchStoreTimings: 'FETCH_STORE_TIMINGS',
  FetchStoreTimingsArray: 'FETCH_STORE_TIMINGS_ARRAY',
  FetchAllSuburbs: 'FETCH_ALL_SUBURB',
  FetchAllShifts: 'FETCH_ALL_SHIFTS',
  FetchedIngredientCategories: 'FETCHED_INGREDIENT_CATEGORIES',
  FetchCloseDates: 'FETCHED_CLOSE_DATES',
};

export default actionTypes;
