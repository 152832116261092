import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchMenu:
      return { ...state, categories: payload.categories };
    case ActionTypes.FetchAllSpecials:
      return { ...state, allSpecials: payload.allSpecials };
    case ActionTypes.FetchMenuItems:
      return { ...state, menuItems: payload.menuItems };
    case ActionTypes.FetchHalfHalf:
      return { ...state, halfHalf: payload.halfhalf };
    case ActionTypes.FetchOrderSetup:
      return { ...state, orderSetup: payload.orderSetup };
    case ActionTypes.FetchUserByUid:
      return {
        ...state,
        currentUser: payload.currentUser,
        userDetails: payload.userDetails,
        pathwayToOffers: payload.pathwayToOffers,
      };
    case ActionTypes.LoadCurrentOrder:
      return { ...state, currentOrder: payload.currentOrder };
    case ActionTypes.OrderCountFetched:
      return { ...state, orderCount: payload.orderCount };
    case ActionTypes.PopularsFetched:
      return { ...state, populars: payload.populars };
    case ActionTypes.FetchedCategories:
      return { ...state, menuCategories: payload.menuCategories };
    case ActionTypes.FetchedReservations:
      return { ...state, currentReservations: payload.currentReservations };
    case ActionTypes.FetchStoreInfo:
      return { ...state, store: payload.store };
    case ActionTypes.FetchItemSizes:
      return { ...state, itemSizes: payload.itemSizes };
    case ActionTypes.FetchStoreOffers:
      return { ...state, offers: payload.offers };
    case ActionTypes.FetchPublicHoliday:
      return {
        ...state,
        publicHolidays: [
          ...(state.publicHolidays ? state.publicHolidays : []),
          payload.publicHoliday,
        ],
      };
    default:
      return state;
  }
};
