const actionTypes = {
  FetchAllSpecials: 'FETCH_ALL_SPECIALS',
  FetchMenu: 'FETCH_MENU',
  FetchMenuItems: 'FETCH_MENU_ITEMS',
  FetchHalfHalf: 'FETCHED_HALF_HALF',
  FetchItemSizes: 'FETCHED_ITEM_SIZES',
  FetchOrderSetup: 'FETCH_ORDER_SETUP',
  FetchUserByUid: 'FETCH_USER_BY_UID',
  LoadCurrentOrder: 'LOAD_CURRENT_ORDER',
  OrderCountFetched: 'ORDER_COUNT_FETCHED',
  PopularsFetched: 'POPULARS_FETCHED',
  FetchedCategories: 'FETCH_CATEGORIES',
  FetchedReservations: 'FETCH_RESERVATIONS',
  FetchStoreInfo: 'FETCH_STORE_INFO',
  FetchStoreOffers: 'FETCH_STORE_OFFERS',
  FetchPublicHoliday: 'FETCH_PUBLIC_HOLIDAY',
};

export default actionTypes;
